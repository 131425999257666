.about-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.about-content-row {
  flex-grow: 1;
}

.about-left-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-right-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-left-container p {
  font-size: 20px;
  line-height: 27px;
  font-family: Exo-Regular;
  width: 574px;
}

.worker-info-container {
  height: 100px;
  margin-bottom: 50px;
  display: flex; 
  width: 600px;
}

.worker-info-container span {
  font-family: Exo-SemiBold;
  font-size: 20px;
  margin-left: 50px;
}

.text-gang {
  transform: rotate(-5deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-5deg);
  /* Firefox */
  -moz-transform: rotate(-5deg);
  /* IE */
  -ms-transform: rotate(-5deg);
  /* Opera */
  -o-transform: rotate(-5deg  );
  text-transform: uppercase;
  font-family: Exo-SemiBold;
  font-size: 20px;
  margin-left: 120px;
}

.gang1 {
  color: #456B43;
}

.gang2 {
  color: #D15D6C;
}

.gang3 {
  color: #CD7FBB;
}

@media (max-width: 1200px) {
  .about-right-container {
    align-items: center;
  }
}

@media (max-width: 576px) {
  .about-right-container {
    justify-content: flex-start;
  }
  .about-left-container p {
    font-size: 12px;
    line-height: 16px;
    width: 335px;
  }
  .worker-info-container {
    margin-bottom: 25px;
    height: 50px;
    width: 300px;
  }
  .worker-info-container img {
    width: 50px;
    height: 50px;
  }
  .worker-info-container span {
    font-size: 10px;
    margin-left: 25px;
  }
  .text-gang {
    font-size: 10px;
    margin-left: 60px;
  }
}