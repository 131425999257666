.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

/* Buttons */
.btn {
  font-family: Exo-SemiBold;
}
.btn:focus {
  box-shadow: none !important;
}

.arrow {
  display: none !important;
}
.popover {
  background: #131313;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  font-family: Exo-Regular;
}
.popover-body {
  font-family: Exo-Regular !important;
  color: #fff;
}

/* Navigation bar */
.tw-navbar {
  padding: 0px;
}

/* Join community Component */
.join-community {
  display: flex;
  flex-direction: row;
  margin-right: 52px;
  align-items: center;
}
.discord-icon {
  margin-left: 23px;
}
.discord-icon:hover path {
  fill: var(--tw-color);
}
/* End Join community Component */

/* Language selector */
.btn-language-selector {
  color: var(--tw-color);
  text-transform: uppercase;
  font-size: 24px;
  line-height: 32px;
  margin-right: 32px;
  margin-top: 22px;
  margin-left: 33px;
  text-decoration: underline;
  padding: 0px;
}
/* End Language selector */

/* Navbar mobile menu*/
.tw-navbar-opened {
  padding: 0px;
  background: linear-gradient(180deg, #DDBE38 0%, #FFE600 100%);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.tw-navbar-header {
  min-width: 100%;
  height: 45px;
  display: flex;
}
.tw-navbar-header-left {
  flex: 1;
  display: flex;
}
.tw-navbar-header-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.home-button {
  display: flex;
  margin-left: 10px;
  width: 100px;
  background: #131313;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.home-button > div {
  background-image: url('../../resources/images/common/ic_round-home.png');
  width: 30px;
  height: 30px;
}
.tw-navbar-opened .nav-link {
  margin-right: 0px;
  margin-bottom: 49px;
  color: #131313 !important;
}
.tw-navbar-opened .navbar-collapse {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.tw-navbar-opened .ml-auto {
  margin-left: 0px !important;
}
.tw-navbar-opened .nav-link-dywo-icon path {
  fill: #131313 !important;
}
.tw-navbar-footer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.tw-navbar-footer-left {
  flex: 1;
  margin-bottom: 15px;
}
.tw-navbar-footer-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  margin-bottom: 15px;
}
.tw-navbar-footer-right .join-community {
  margin-right: 16px;
  font-size: 6px;
  font-family: Exo-Regular;
}
.tw-navbar-footer-right .join-community > div {
  width: 44px;
  color: #131313;
}
.tw-navbar-footer-right .discord-icon {
  width: 27px;
  height: 30px;
  margin-left: 11px;
}
.tw-navbar-footer-right .discord-icon path {
  fill: #131313;
}
.tw-navbar-footer-left .btn-language-selector {
  color: #131313;
  font-size: 16px;
  margin-left: 15px;
}

/* End Navbar mobile menu*/

/* Override bootstrap styles */
.nav-link {
  font-family: Exo-SemiBold;
  color: #fff !important;
  font-size: 20px !important;
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.nav-link:hover {
  color: var(--tw-color) !important;
}
.nav-link-dywo:hover .nav-link-dywo-icon path  {
  fill: var(--tw-color);
}
.navbar-dark {
  justify-content: flex-end;
  align-items: flex-start;
}
.navbar-toggler {
  background: linear-gradient(180deg, #DDBE38 0%, #FFE600 100%);
  width: 100px;
  height: 45px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-right: 10px;
}
.navbar-toggler-close {
  background: #131313;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url('../../resources/images/common/material-symbols_menu-rounded.svg');
}
.navbar-toggler-close .navbar-toggler-icon {
  background-image: url('../../resources/images/common/material-symbols_menu-rounded-opened.png');
}
/* End Override bootstrap styles */
.nav-link-dywo {
  position: relative;
  width: 230px;
}
.nav-link-dywo-content {
  position: absolute;
}
.kickstarter-logo {
  z-index: 9;
  background: url('../../resources/images/home/kickstarter-logo.svg') no-repeat;
  width: 100%;
  height: 100%;
  background-position: right 0px bottom 9px;
  background-size: 70%;
  -webkit-transition: background-size 0.2s ease-in;
  transition: background-size 0.2s ease-in;
}
.kickstarter-logo:hover {
  background-size: 88%;
}
.nav-link-subscribe {
  color: #2B2827 !important;
  width: 200px;
  height: 90px;
  background: linear-gradient(180deg, var(--tw-color) 0%, #FFE600 100%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-right: 16px;
}
.nav-link-subscribe:hover {
  color: var(--tw-color) !important;
  background: none;
}
.back-button {
  display: flex;
  width: 200px;
  height: 90px;
  background: linear-gradient(180deg, #DDBE38 0%, #FFE600 100%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 100px;
  justify-content: center;
  align-items: center;
}


/* Footer Common */
.footer-tw-logo {
  width: 97px;
  margin-right: 20px;
}

.footer-borderline-logo {
  width: 180px;
  height: 46px;
}

.footer-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.copyright {
  font-family: Exo-Regular;
  font-size: 16px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .footer-tw-logo {
    width: 48px;
    margin-right: 25px;
  }
  .footer-borderline-logo {
    width: 90px;
    height: 23px;
  }
  .copyright {
    font-size: 10px;
  }
}

/* Footer */
@media (min-width: 768px) {
  .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 30px;
    height: 89px;
    padding-left: 70px;
    padding-right: 70px;
  }
}

.footer-column {
  width: 100%;
}

.footer-left {
  display: flex;
  align-items: center ;
}

.footer-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: Exo-Regular;
  font-size: 12px;
}

.footer-right div {
  width: 88px;
  text-align: right;
}

/* Footer light */
.footer-light {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-light-copyright {
  margin-top: 10px;
}

/* Modal */
.tw-modal {
  background-color: #131313;
  width: 640px;
  height: 360px;
}
.modal-body, .modal-footer {
  background-color: #131313;
}

.modal-body {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  font-size: 16px;
  font-family: Exo-Regular;
  line-height: 21px;
  padding-left: 20px;
  padding-right: 20px;
}

.modal-body span {
  font-family: Exo-SemiBold;
}
.modal-body a {
  text-decoration: underline;
  color: #fff;
}
.modal-body p {
  margin-left: 40px;
  margin-right: 40px;
}

.modal-footer {
  border-top: 0px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
}

.dialog-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}