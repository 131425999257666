.account-header {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  color: white;
  background-color: #131313;
}
.account-navbar {
  display: flex;
  flex-direction: row;
}
.account-content {
  width: 640px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.account-content-form {
  flex-grow: 1;
  margin-left: 120px;
  margin-right: 120px;
  margin-top: 60px;
  margin-bottom: 10px;
}

@media (max-width: 1300px) {
  .account-content-form {
    margin-left: 50px;
    margin-right: 50px;
  }
}

.account-background {
  flex: 66%;
  background-image: 
    linear-gradient(180deg, rgba(43, 40, 39, 0) 0%, #131313 100%),
    url('../../resources/images/account/account-background.png');
  background-size: cover;
}

@media (max-width: 700px) {
  .account-background {
    flex: 0%;
    background-image: none;
  }
}

h1 {
  font-family: Exo-SemiBold;
  font-size: 20px;
  color: var(--tw-color);
}

.link
{
  color: var(--tw-color);
  font-weight: bold;
}

.link:hover
{
  color: var(--tw-color);
  font-weight: bold;
}



