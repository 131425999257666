* {
    box-sizing: border-box;
}
  
/* Mobile First */
form {
    padding: 0;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}
  
.form-row-custom {
    padding: 12px 0px 0px 0px;
    align-items:flex-start;
}

.form-row-custom:last-child {
    border: none;
}

.form-row-custom input,
.form-row-custom textarea {
    display: block; 
    box-sizing: border-box;
    outline: none;
    width: 100%;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    padding: 5px 20px 8px 13px;
    background-color: #131313;
}

.form-row-custom input::placeholder {
    color: #fff;
    opacity: 0.25;
}

.text__input label
{
    width: 100%;
}

.input-password__description li
{
    text-align: start;
    margin-left: 20px;
    color: #fff;
    opacity: 0.90;
}
.form-group
{
    margin-bottom: -20px;
}

.submit-row {
    padding: 12px 0px 13px 0px;
    margin: 0;
    margin-top: 20px;
}
  
.form-submit {
    -webkit-appearance: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    color: #000;
    background: linear-gradient(180deg, #DDBE38 0%, #FFE600 100%);
    border-radius: 5px;
    height: 45px;
    width: 100%;
    white-space: nowrap;
    line-height: 40px;
    padding: 0 14px;
    font-size: 16px;
    font-family: Exo-Regular;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .025em;
    text-decoration: none;
    transition: all .15s ease;
}
  
.form-submit:active {
    color: #e6ebf1;
    background-color: #D0982D;
    transform: translateY(1px);
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.input-error-msg {
    font-size: 12px;
    background-color: red;
}

input.invalid__value {
    border-color: var(--tw-color);
  }
  
/* Non Mobile */
  @media (min-width: 670px) {
    .form-row-custom {
        /*max-width: 600px;*/
        display: block;
    }
  
    .form-row-custom input,
    .form-row-custom textarea {
        width: 100%;
    }
  
    .submit-row {
        /*max-width: 600px;*/
    }
  }