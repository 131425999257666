.header-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.header-content-row {
  flex-grow: 1;
}

.header-left-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.open-state {
  width: 400px;
  height: 177px;
  background: url("../../resources/images/home/open/variant1.png") no-repeat;
  background-size: 337px 137px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 100px;
}

.pres-container {
  width: 478px;
  font-size: 24px;
  line-height: 32px;
  font-family: Exo-Regular;
  margin-top: 50px;
}

.pres-container p {
  margin-top: 30px;
  margin-bottom: 45px;
}

.pres-container p a{
  font-family: Exo-SemiBold;
  color: var(--tw-color);
}

.pres-container p a:hover{
  text-decoration: none;
}

.actions-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.action-btn {
  box-sizing: border-box;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  width: 200px;
  height: 63px;
  font-size: 20px;
}

.download-btn-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.btn-requirement {
  font-family: Exo-Regular !important;
  font-size: 12px !important;
  line-height: 16px;
  color: #fff !important;
  text-decoration: underline;
}
.popover-requirement {
  max-width: 450px !important;
}

.btn-download { 
  background: linear-gradient(180deg, #ED7E38 0%, #CD5408 100%);
  margin-bottom: 10px
}
.btn-download:hover {
  color: #fff;
}
.btn-download:disabled { 
  background: #2B2827;
  color: #131313;;
}

.btn-register {
  background: linear-gradient(180deg, var(--tw-color) 0%, #FFE600 100%);
  margin-left: 21px;
  color: #000;
}
.btn-register:hover {
  color: var(--tw-color) !important;
  background: none;
}

@media (min-width: 1400px) {
  .header-left-image {
    width: 492px;
    height: 508px;
  }
}

@media (max-width: 1200px) {
  .header-left-image {
    width: 418px;
    height: 431px;
  }
}

@media (max-width: 992px) {
  .header-left-image {
    width: 344px;
    height: 355px;
  }
}

@media (max-width: 768px) {
  .header-left-image {
    width: 265px;
    height: 274px;
  }
}

@media (max-width: 576px) {
  .header-left-image {
    width: 246px;
    height: 254px;
    margin-top: 50px;
  }
  .header-right-container {
    justify-content: flex-start;
    height: auto;
  }
  .open-state {
    width: 200px;
    height: 70px;
    background-size: 165px 57px;
    margin-top: 30px;
  }
  .open-state img {
    width: 85px;
    height: 43px;
  }
  .pres-container {
    width: 239px;
    font-size: 12px;
    line-height: 15px;
  }
  .action-btn {
    width: 100px;
    height: 31px;
    font-size: 10px;
  }
  .btn-download { 
    margin-bottom: 3px;
  }
  .btn-register {
    margin-right: 10px;
  }
  .btn-register-icon {
    height: 0px;
    width: 0px;
  } 
  .btn-register:hover .btn-register-icon {
    visibility: collapse;
  }
  .download-btn-container span {
    font-size: 6px;
  }
  .popover-requirement {
    max-width: 300px !important;
  }
}

/* dialogs */
.btn-effective-download {
  box-sizing: border-box;
  border-radius: 0px;
  color: #fff;
  text-transform: uppercase;
  width: 200px;
  height: 60px;
  border: 1px solid #FFFFFF;
  font-family: Exo-Regular;
  font-size: 16px;
  line-height: 21px;
}
