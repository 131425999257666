
/* Common fonts */
@font-face {
  font-family: Exo-SemiBold;
  src: url(resources/fonts/Exo/Exo-SemiBold.ttf);
}

@font-face {
  font-family: Exo-Regular;
  src: url(resources/fonts/Exo/Exo-Regular.ttf);
}

/* Common colors */
:root {
  --tw-color: #DDBE38;
}

/* Common styles */
.tw-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.error-msg-style {
  color: red;
}

.sucess-msg-style {
  color: #fff;
}

h1 {
  font-size: calc(1.5rem + 1vw);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
  color: #fff;
  font-size: 13px;
}
